<script lang="ts">
	import "../assets/css/main.css";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { getContext } from "svelte";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey.js";
	import { titleContextKey } from "../contexts/titleContextKey.js";
	import { descriptionContextKey } from "../contexts/descriptionContextKey.js";
	import Footer from "./Footer.svelte";
	import MarketingFooter from "./MarketingFooter.svelte";
	import Header from "./Header.svelte";
	import HeadFavicons from "./HeadFavicons.svelte";
	import { menuContextKey } from "../contexts/menuContextKey.js";
	import type { MenuItem } from "../../../core/schema/MenuItem";
	import GoogleAnalytics from "./GoogleAnalytics.svelte";
	import MetaPixel from "./MetaPixel.svelte";
	import type { Breadcrumb } from "../../../core/schema/Breadcrumb.js";
	import { breadcrumbsContextKey } from "../contexts/breadcrumbsContextKey.js";
	import Breadcrumbs from "./Breadcrumbs.svelte";
	import { appName } from "../../../core/schema/names/appName.js";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import basierCircleRegular from "../../../core/assets/fonts/basier-circle/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff2";
	import basierCircleMedium from "../../../core/assets/fonts/basier-circle/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff2";
	import basierCircleSemibold from "../../../core/assets/fonts/basier-circle/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff2";
	import basierCircleBold from "../../../core/assets/fonts/basier-circle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff2";
	import { announcementContextKey } from "../contexts/announcementContextKey.js";
	import thumbnail from "../assets/images/thumbnail.png";
	import { createTitle } from "../utils/createTitle.js";
	import { keywordsContextKey } from "../contexts/keywordsContextKey.js";
	import { smartlookEnabledContextKey } from "../contexts/smartlookEnabledContextKey.js";
	import Smartlook from "./Smartlook.svelte";
	import { thumbnailImageContextKey } from "../contexts/thumbnailImageContextKey.js";
	import { indexContextKey } from "../contexts/indexContextKey.js";

	export let hasPanel = false;
	export let hideFooter = false;

	const { ROBOTS, WEB_URL } = getGlobalSettings();

	const breadcrumbs = getContext<Breadcrumb[]>(breadcrumbsContextKey);
	const pageSlug = getContext<string>(pageSlugContextKey);
	const title = createTitle(getContext<string | undefined>(titleContextKey));
	const description = getContext<string>(descriptionContextKey);
	const keywords = getContext<string | undefined>(keywordsContextKey);
	const menu = getContext<MenuItem[]>(menuContextKey);
	const announcement = getContext<string | undefined>(announcementContextKey);
	const smartlookEnabled = getContext<boolean>(smartlookEnabledContextKey);
	const index = getContext<boolean>(indexContextKey);
	const ogImage = getContext<string | undefined>(thumbnailImageContextKey) ?? thumbnail;

	const canonical = `${WEB_URL}/${pageSlug}`;
	$: {
		document.documentElement.style.overflow = $scrollLockStore ? "hidden" : "auto";
	}
</script>

<svelte:head>
	<meta property="og:site_name" content={appName} />
	<meta name="twitter:site" content={appName} />
	<meta name="application-name" content={appName} />
	<meta name="apple-mobile-web-app-title" content={appName} />
	<meta name="robots" content={ROBOTS === "index" && index ? "index, follow" : "noindex, nofollow"} />
	{#each [basierCircleRegular, basierCircleMedium, basierCircleSemibold, basierCircleBold] as font}
		<link rel="preload" as="font" type="font/woff2" href={font} crossorigin="anonymous" />
	{/each}
	<link rel="canonical" href={canonical} />

	<!-- Dynamic Open graph tags (Meta) -->
	<meta property="og:url" content={canonical} />
	<meta property="og:image" content={ogImage} />

	<!-- Dynamic Twitter Meta tags -->
	<meta name="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content={WEB_URL} />
	<meta property="twitter:url" content={canonical} />
	<meta name="twitter:image" content={ogImage} />

	<!-- Title and description -->
	<title>{title}</title>
	<meta property="og:title" content={title} />
	<meta property="og:title" content={title} />
	<meta name="twitter:title" content={title} />
	<meta name="description" content={description} />
	<meta name="og:description" content={description} />
	<meta name="twitter:description" content={description} />

	{#if keywords}
		<meta name="keywords" content={keywords} />
	{/if}
</svelte:head>

<Smartlook enabled={smartlookEnabled} />

<HeadFavicons />

<GoogleAnalytics />

<MetaPixel />

<div class="contents">
	<Header {menu} {announcement} />

	<main class="grow pb-12 pt-24 md:pt-[7.5rem] lg:pb-24 xl:pt-[9rem]">
		<Breadcrumbs {breadcrumbs} />

		<slot />
	</main>

	{#if !hideFooter}
		<Footer {hasPanel} {menu} />
	{/if}
</div>

<MarketingFooter {hasPanel} />
