<script lang="ts">
	import { getGlobalSettings } from "../getGlobalSettings.js";

	const { GOOGLE_ANALYTICS, GOOGLE_ADS } = getGlobalSettings();

	/* eslint-disable */
</script>

<svelte:head>
	{@html `<script async src="https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS}"></script>`}
	{@html `
	<script>
		window.dataLayer ||= [];
		function gtag(){window.dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${GOOGLE_ANALYTICS}');
		gtag('config', '${GOOGLE_ADS}');
	</script>
	`}
</svelte:head>
