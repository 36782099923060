<script lang="ts">
	import Button from "./Button.svelte";
	import logo from "../../../core/assets/logo.svg";
	import Icon from "./Icon.svelte";
	import menuIcon from "../../../core/assets/icons/macaroon.svg?raw";
	import type { MenuItem } from "../../../core/schema/MenuItem.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { cartPathMap } from "../../../core/schema/paths/cartPathMap.js";
	import Link from "./Link.svelte";
	import basket from "../../../core/assets/icons/basket.svg?raw";
	import { appName } from "../../../core/schema/names/appName.js";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import person from "../../../core/assets/icons/person.svg?raw";
	import Drawer from "./Drawer/Drawer.svelte";
	import Announcement from "./Announcement.svelte";
	import MobileMenu from "./Menu/MobileMenu.svelte";
	import DesktopMenu from "./Menu/DesktopMenu.svelte";
	import { showHeaderStore } from "../stores/showHeaderStore.js";
	import { signInPathMap } from "../../../core/schema/paths/signInPathMap.js";
	import { loggedCustomerStore } from "../stores/loggedCustomerStore.js";
	import { myAccountPathMap } from "../../../core/schema/paths/myAccountPathMap.js";
	import { animateCartCountStore } from "../stores/animateCartCountStore.js";
	import { getCartStore } from "../contexts/cartStoreContextKey.js";
	import { isCartEmpty } from "../stores/cart/isCartEmpty.js";
	import { getItemAmountInOrder } from "../../../core/schema/utils/getItemAmountInOrder";

	export let menu: MenuItem[];
	export let announcement: string | undefined = undefined;

	const cartStore = getCartStore();
	const { TENANT_LOCALE } = getGlobalSettings();

	let isMenuOpen = false;

	function toggleMenu(): void {
		isMenuOpen = !isMenuOpen;
	}

	let scrollPosition = 0;
	let scrollFromLastDirectionChange = 0;
	const scrollTolerance = 25;
	let showHeader = true;

	function onScroll(event: UIEvent & { currentTarget: EventTarget & Window }): void {
		const { scrollY } = event.currentTarget;
		const newScrollPosition = Math.max(scrollY, 0);
		const oldDirection = scrollFromLastDirectionChange < 0 ? "up" : "down";
		const newDirection = newScrollPosition > scrollPosition ? "down" : "up";

		if (oldDirection !== newDirection) {
			scrollFromLastDirectionChange = 0;
		}
		scrollFromLastDirectionChange += newScrollPosition - scrollPosition;

		scrollPosition = newScrollPosition;

		if (Math.abs(scrollFromLastDirectionChange) > scrollTolerance) {
			showHeader = scrollFromLastDirectionChange < 0;
		}
	}
</script>

<svelte:window on:scroll={onScroll} />

{#if announcement}
	<Announcement {announcement} />
{/if}

{#if menu.length > 0}
	<Drawer bind:isOpen={isMenuOpen} position="left" dontShowCloseButton class="w-[16.0625rem]">
		<MobileMenu bind:isOpen={isMenuOpen} {menu} />
	</Drawer>
{/if}

<!-- TODO - grayscale added to prevent flickering of header on page scroll in FireFox - https://stackoverflow.com/questions/30411909/css-transition-flickering-in-firefox -->
<header
	class="border-b-1 fixed left-0 right-0 top-0 z-30 h-20 w-full border-solid bg-white py-3 grayscale-[1%] transition-[border,transform] duration-300 md:h-[5.75rem] lg:h-auto lg:py-6"
	class:top-8={announcement}
	class:border-gray-200={scrollPosition > 0}
	class:border-transparent={scrollPosition <= 0}
	class:-translate-y-full={!showHeader && !$showHeaderStore}
	class:translate-y-0={showHeader || $showHeaderStore}
>
	<div class="container flex h-full items-center justify-between gap-4 lg:h-fit lg:gap-2 xl:gap-12">
		<Button class="lg:hidden" variant="transparent" on:click={toggleMenu} aria-label="Menu">
			<Icon icon={menuIcon} class="text-secondary w-8 md:w-9 lg:w-10" />
			<span class="sm:text-2xs text-[0.6rem] font-semibold uppercase leading-4 tracking-[0.15rem]">Menu</span>
		</Button>
		<div class="flex items-center justify-center lg:w-20 lg:min-w-[80px] xl:w-24 xl:min-w-[96px]">
			<a
				aria-label="Logo {appName}"
				href="/"
				class="absolute bottom-3 left-0 right-0 top-3 m-auto w-[3.5rem] shrink-0 transition-[height,width] duration-200 md:w-[4rem] lg:relative lg:bottom-[initial] lg:top-[initial] {scrollPosition ===
				0
					? 'lg:h-20 lg:w-20 xl:h-24 xl:w-24'
					: 'lg:h-16 lg:w-16'}"
			>
				<StaticPicture alt="Logo {appName}" image={logo} class="w-full" loading="eager" width={96} height={96} />
			</a>
		</div>
		{#if menu.length > 0}
			<DesktopMenu {menu} />
		{/if}
		<div class="inline-flex h-9 gap-2 pt-1 md:pt-0">
			<Link
				aria-label="Krabice"
				href="/{cartPathMap[TENANT_LOCALE]}"
				asButton
				class="relative"
				iconClass="w-4 absolute xl:relative left-0 right-0 mx-auto"
				icon={basket}
				variant="secondaryIcon"
			>
				<span class="hidden text-[.9rem] lg:text-base xl:block">Krabice</span>
				{#if $cartStore.loaded && !isCartEmpty($cartStore.value)}
					<span
						class="bg-primary text-2xs absolute right-0 top-[1.625rem] flex h-4 w-fit min-w-4 items-center justify-center rounded-full p-1 text-white md:h-[1.125rem] md:min-w-[1.125rem] xl:top-[1.6875rem]"
						class:animate-flash={$animateCartCountStore}
					>
						{getItemAmountInOrder($cartStore.value.order)}
					</span>
				{/if}
			</Link>

			<Link
				aria-label={$loggedCustomerStore.value ? "Můj účet" : "Přihlášení"}
				href="/{($loggedCustomerStore.value ? myAccountPathMap : signInPathMap)[TENANT_LOCALE]}"
				variant="secondaryIcon"
				class="relative"
				icon={person}
				iconClass="absolute xl:hidden top-0 bottom-0 right-0"
				asButton
			>
				<span
					class="group-hover:text-primary hidden place-items-center whitespace-nowrap transition-colors duration-300 [grid-template-areas:'center'] xl:inline-grid"
				>
					<span class="opacity-0 [grid-area:center]" class:opacity-100={$loggedCustomerStore.value}>Můj účet</span>
					<span class="opacity-0 [grid-area:center]" class:opacity-100={!$loggedCustomerStore.value}>Přihlášení</span>
				</span>
			</Link>
		</div>
	</div>
</header>
